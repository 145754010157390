import React, { useState, useEffect } from 'react';
import classnames from 'classnames'

export function Team({ src, name, position, email, phone, bio }) {

    const [active, setActive] = useState(false)
    const handleOnclick = () => {
        setActive(!active)
    }


    return (
        <div className="team-management-block">
            <div className="team-member-img">
                <img src={src} alt={name} />
            </div>

            <div className="team-member__info">
                <h5 className="team-member__name">{name}</h5>
                <h6 className="team-member__job-title">{position}</h6>
            </div>
        </div>
    );
}
