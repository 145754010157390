import React from 'react';
import BreadCrumbs from '../../components/BreadCrumbs';
import breadcrumb from '../../assets/img/breadcrumb.jpg'

export default function ContactUs() {
  return (
    <div className="l-site-content js-site-content">

      <BreadCrumbs
        url={breadcrumb}
        title="Contact Us"
      />

      <div className="l-main-w-sidebar contact-us">
        <div className="l-main-w-sidebar__main">

          <div className="blurb blurb--wide blurb--xl-sectra">
            <h2 className="blurb__heading">
              We do whatever&nbsp;<br /> it&nbsp;takes.
            </h2>
          </div>
          <div className="form js-form" data-module="form-fields">
            <div className="form__heading">
              <h2 className="body-robinson-l">How can we help?</h2>
            </div>

            <form>
              <div className="freeform-row ">
                <div className="freeform-column ">
                  <label for="form-input-firstName" className="freeform-label">First Name</label>
                  <input className="freeform-input " name="firstName" type="text" id="form-input-firstName" />
                </div>
                <div className="freeform-column ">
                  <label for="form-input-lastName" className="freeform-label">Last Name</label>
                  <input className="freeform-input " name="lastName" type="text" id="form-input-lastName" />


                </div>
              </div>
              <div className="freeform-row ">
                <div className="freeform-column ">
                  <label for="form-input-companyName" className="freeform-label">Company Name</label>
                  <input className="freeform-input " name="companyName" type="text" id="form-input-companyName" />


                </div>
              </div>
              <div className="freeform-row ">
                <div className="freeform-column ">
                  <label for="form-input-email" className="freeform-label freeform-required">Email</label>
                  <input className="freeform-input" name="email" type="email" id="form-input-email" data-required />


                </div>
              </div>

              <div className="freeform-row ">
                <div className="freeform-column ">
                  <label for="form-input-message" className="freeform-label">Additional Comments</label>
                  <textarea className="freeform-input" name="message" id="form-input-message" rows="5"></textarea>


                </div>
              </div>
              <div className="freeform-row ">
                <div className="freeform-column  freeform-column-content-align-left">
                  <button disabled data-submit-button="1" type="submit" name="form_page_submit">Submit</button>


                </div>
              </div>


            </form>

          </div>


        </div>

        <div className="l-main-w-sidebar__sidebar js-parallax-container">
          <div className="l-main-w-sidebar__sidebar-pattern js-sidebar-pattern js-parallax-object"
            data-module="parallax" data-scroll="800">
            <img className="lazyload fill-img"
              src="data:image/jpeg;base64,%2F9j%2F4AAQSkZJRgABAQAASABIAAD%2F2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P%2F2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P%2FwAARCAAxABADASIAAhEBAxEB%2F8QAGQAAAwEBAQAAAAAAAAAAAAAAAAECAwQG%2F8QAHxAAAgIDAAIDAAAAAAAAAAAAAQIAERIhMQNRIkFh%2F8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC%2F8QAFBEBAAAAAAAAAAAAAAAAAAAAAP%2FaAAwDAQACEQMRAD8A8iimtjRiJCnGyYx5C5xA7Kdcl1VyMgKq2AdmZfJG9GIA3XZuQrDY3AQwPKsckAsG3f6ZIPqW7nKq1AzHJ0fQhCFf%2F9k%3D"
              data-sizes="auto" />
          </div>


          <div className="sidebar">

            <div className="sidebar__group">

              <div className="sidebar__group-heading">
                <h5 className="eyebrow-xl" sty>Locations</h5>
              </div>

              <div className="location">
                <h6 className="location__heading">Zora Corporation</h6>
                <a href="https://www.google.com/maps/search/?api=1&amp;query=100%20Lexington%20Drive%0ASuite%20201%0ABuffalo%20Grove%2C%20IL%2060089"
                  target="_blank">
                  <address className="location__address">
                    199 E Montgomery Ave,<br />
                    Suite 100,<br />
                    Rockville, MD 20850<br />
                  </address>
                </a>
                <a href="tel:8478083030" className="location__phone">301.437.1255</a>
              </div>

            </div>

            <div className="sidebar__group">
              <div className="sidebar__group-heading">
                <h5 className="eyebrow-xl">Email Us</h5>
              </div>

              <div className="sidebar__links">
                <a href="mailto:info@zoracorp.com"
                  className="eyebrow-l">info@zoracorp.com</a>
              </div>
            </div>

            {/*<div className="sidebar__group">*/}
            {/*  <div className="sidebar__group-heading">*/}
            {/*    <h5 className="eyebrow-xl">Follow Us</h5>*/}
            {/*  </div>*/}

            {/*  <div className="sidebar__links">*/}
            {/*    <a href="https://www.linkedin.com/company/ruby-robinson-co.-inc." className="sidebar__social"*/}
            {/*      target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px"*/}
            {/*        viewBox="0 0 18 18">*/}
            {/*        <path*/}
            {/*          d="M.218 17.383h3.71V6.231H.218zM2.072 4.707H2.05C.804 4.707 0 3.849 0 2.779 0 1.685.83.851 2.096.851c1.27 0 2.051.834 2.074 1.928 0 1.07-.805 1.928-2.098 1.928M17.297 17.384H13.59v-5.968c0-1.498-.536-2.523-1.879-2.523-1.022 0-1.634.693-1.902 1.358-.099.239-.123.569-.123.904v6.229H5.978s.048-10.108 0-11.153h3.707v1.58c.494-.763 1.374-1.842 3.343-1.842 2.439 0 4.27 1.592 4.27 5.02v6.395z">*/}
            {/*        </path>*/}
            {/*      </svg>*/}
            {/*    </a>*/}
            {/*    <a href="https://www.facebook.com/TheRubyCo/" className="sidebar__social" target="_blank"><svg*/}
            {/*      xmlns="http://www.w3.org/2000/svg" width="9px" height="18px" viewBox="0 0 9 18">*/}
            {/*      <path*/}
            {/*        d="M8.4 8.655H5.976v8.642H2.385V8.655H.675V5.602h1.71V3.626C2.385 2.21 3.056 0 6.009 0l2.66.01v2.965H6.74c-.315 0-.763.157-.763.833v1.794h2.739L8.4 8.655z">*/}
            {/*      </path>*/}
            {/*    </svg>*/}
            {/*    </a>*/}
            {/*    <a href="https://www.instagram.com/_therubycompany/" className="sidebar__social" target="_blank"><svg*/}
            {/*      xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">*/}
            {/*      <path d="M7.75 5.165a2.583 2.583 0 100 5.166 2.583 2.583 0 000-5.166"></path>*/}
            {/*      <path*/}
            {/*        d="M11.885 4.542a.93.93 0 11-.001-1.859.93.93 0 010 1.86M7.75 11.727a3.98 3.98 0 110-7.958 3.98 3.98 0 010 7.958m7.7-7.174c-.036-.825-.167-1.389-.358-1.88a3.787 3.787 0 00-.894-1.374 3.796 3.796 0 00-1.373-.894c-.493-.19-1.055-.322-1.88-.36C10.117.01 9.853 0 7.749 0s-2.37.01-3.195.046c-.824.038-1.388.17-1.881.36-.51.198-.941.463-1.372.894-.431.43-.696.863-.895 1.373-.191.492-.322 1.056-.36 1.881C.01 5.38 0 5.644 0 7.748c0 2.105.009 2.368.047 3.195.037.825.168 1.388.359 1.881a3.8 3.8 0 00.895 1.372 3.78 3.78 0 001.372.894c.493.191 1.057.323 1.88.36.827.038 1.092.047 3.196.047s2.368-.009 3.195-.047c.825-.037 1.387-.169 1.88-.36.51-.197.942-.463 1.373-.894.43-.43.696-.862.894-1.372.19-.493.322-1.056.359-1.88.039-.828.047-1.09.047-3.196 0-2.104-.008-2.368-.047-3.194">*/}
            {/*      </path>*/}
            {/*    </svg>*/}
            {/*    </a>*/}
            {/*  </div>*/}
            {/*</div>*/}

          </div>
        </div>
      </div>

    </div>
  );
}
