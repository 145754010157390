import React from 'react';
import './bannar.css';


export default function Bannar(props) {
    return (
        <div className="hero hero--fit-height hero--reverse-text hero--home hero--animate-btn js-anim-in" data-module="parallax">

            <div className="hero__mobile-img">
                <img className="fill-img lazyload"
                    src="data:image/jpeg;base64,%2F9j%2F4AAQSkZJRgABAQAAAQABAAD%2F2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P%2F2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P%2FwAARCAATABADAREAAhEBAxEB%2F8QAFgABAQEAAAAAAAAAAAAAAAAABAUG%2F8QAIRAAAgEDBAMBAAAAAAAAAAAAAQIAAwQRBSEjQRIiUXL%2FxAAWAQEBAQAAAAAAAAAAAAAAAAADAgT%2FxAAZEQEBAAMBAAAAAAAAAAAAAAAAAQIREjH%2F2gAMAwEAAhEDEQA%2FAB3VgtKgp8ck%2FJmltLoNdPDe22Iu06aK8dVpJk4wvUzykTHZinGEAO%2FcTuD6kK1raxWEvPxNQ8K%2FmXQV%2F9k%3D"
                    data-srcset="https://ruby-co.imgix.net/full-width-images/ruby-our-produce-fpo-01.jpg?crop=focalpoint&amp;domain=ruby-co.imgix.net&amp;fit=crop&amp;fm=pjpg&amp;fp-x=0.5&amp;fp-y=0.5&amp;h=1496&amp;ixlib=php-2.3.0&amp;q=82&amp;w=1200 1200w, https://ruby-co.imgix.net/full-width-images/ruby-our-produce-fpo-01.jpg?crop=focalpoint&amp;domain=ruby-co.imgix.net&amp;fit=crop&amp;fm=pjpg&amp;fp-x=0.5&amp;fp-y=0.5&amp;h=2993&amp;ixlib=php-2.3.0&amp;q=61&amp;w=2400 2400w, https://ruby-co.imgix.net/full-width-images/ruby-our-produce-fpo-01.jpg?crop=focalpoint&amp;domain=ruby-co.imgix.net&amp;fit=crop&amp;fm=pjpg&amp;fp-x=0.5&amp;fp-y=0.5&amp;h=1237&amp;ixlib=php-2.3.0&amp;q=82&amp;w=992 992w, https://ruby-co.imgix.net/full-width-images/ruby-our-produce-fpo-01.jpg?crop=focalpoint&amp;domain=ruby-co.imgix.net&amp;fit=crop&amp;fm=pjpg&amp;fp-x=0.5&amp;fp-y=0.5&amp;h=2474&amp;ixlib=php-2.3.0&amp;q=61&amp;w=1984 1984w, https://ruby-co.imgix.net/full-width-images/ruby-our-produce-fpo-01.jpg?crop=focalpoint&amp;domain=ruby-co.imgix.net&amp;fit=crop&amp;fm=pjpg&amp;fp-x=0.5&amp;fp-y=0.5&amp;h=957&amp;ixlib=php-2.3.0&amp;q=60&amp;w=768 768w, https://ruby-co.imgix.net/full-width-images/ruby-our-produce-fpo-01.jpg?crop=focalpoint&amp;domain=ruby-co.imgix.net&amp;fit=crop&amp;fm=pjpg&amp;fp-x=0.5&amp;fp-y=0.5&amp;h=1915&amp;ixlib=php-2.3.0&amp;q=45&amp;w=1536 1536w, https://ruby-co.imgix.net/full-width-images/ruby-our-produce-fpo-01.jpg?crop=focalpoint&amp;domain=ruby-co.imgix.net&amp;fit=crop&amp;fm=pjpg&amp;fp-x=0.5&amp;fp-y=0.5&amp;h=432&amp;ixlib=php-2.3.0&amp;q=60&amp;usm=50&amp;w=576 576w, https://ruby-co.imgix.net/full-width-images/ruby-our-produce-fpo-01.jpg?crop=focalpoint&amp;domain=ruby-co.imgix.net&amp;fit=crop&amp;fm=pjpg&amp;fp-x=0.5&amp;fp-y=0.5&amp;h=864&amp;ixlib=php-2.3.0&amp;q=45&amp;w=1152 1152w"
                    data-sizes="auto" />
            </div>

            <div className="hero__video">
                <video src="https://zora-bannar-video.s3.amazonaws.com/final-bg-video.mp4" loop autoPlay muted />
            </div>

            <div className="hero__text">
                <h1 className="heading-robinson-xxl">
                    <span className=""><span className="">Our products & services inspire creativity and enrich lives</span></span>

                </h1>
            </div>

            <div className="hero__btn">
                <a href="services/expertise.html" className="btn">The Ruby Difference</a>
            </div>
        </div>
    )
}
