import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import logo from '../../assets/img/zora_full.png';

// import logo from '../../assets/img/logo.png';

function Header(props) {

    const [scroll, setScroll] = useState(false);
    const [active, setActive] = useState(false);
    useEffect(() => {

        const handleScroll = () => {
            setScroll(window.scrollY > 50);
        }
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);

    }, []);

    const toggleMenu = () => {
        setActive(!active)
    }


    return (
        // NavBar
        <div>
            <div className="l-site-header">
                <div className="l-site-header__inner">

                    <nav className={classnames("nav js-nav", { "is-scrolled": scroll })} data-module="nav">

                        <div className="nav__logo" >
                            <Link to="/">
                                <img src={logo} alt="Logo" />
                            </Link>

                        </div>

                        <div className="nav__open js-nav-toggle" onClick={() => toggleMenu()}><span></span></div>

                        <div className={classnames("nav__right", { "active": active })}>

                            <ul className="nav__links">


                                <li className="nav__link" data-section="company">
                                    <Link to='/' className='js-nav-top-link' onClick={() => toggleMenu()}>Home</Link>
                                </li>

                                <li className="nav__link" data-section="company">
                                    <Link to='/about-us' className='js-nav-top-link' onClick={() => toggleMenu()}>About Us</Link>
                                </li>

                                <li className="nav__link" data-section="company">
                                    <Link to='/products' className='js-nav-top-link' onClick={() => toggleMenu()}>Products</Link>
                                </li>

                                <li className="nav__link" data-section="company">
                                    <Link to='/contact-us' className='js-nav-top-link' onClick={() => toggleMenu()}>Contact Us</Link>
                                </li>

                            </ul>
                        </div>

                    </nav>

                </div>
            </div >
            {/* <nav className="mobile-nav js-mobile-nav">

                <div className="mobile-nav__close js-nav-toggle"></div>

                <ul className="mobile-nav__links">


                    <li className="mobile-nav__link">

                        <span className="mobile-nav__subnav-toggle js-mobile-nav-subnav-toggle">Company</span>

                        <ul className="mobile-nav__subnav js-mobile-nav-subnav">
                            <li className="mobile-nav__subnav-item"><a href="company/about.html">About Us</a></li>
                            <li className="mobile-nav__subnav-item"><a href="company/partners.html">Our Partners</a></li>
                            <li className="mobile-nav__subnav-item"><a href="company/careers.html">Careers</a></li>
                        </ul>


                    </li>

                    <li className="mobile-nav__link">

                        <span className="mobile-nav__subnav-toggle js-mobile-nav-subnav-toggle">What We Do</span>

                        <ul className="mobile-nav__subnav js-mobile-nav-subnav">
                            <li className="mobile-nav__subnav-item"><a href="services/expertise.html">Expertise</a></li>
                            <li className="mobile-nav__subnav-item"><a href="services/logistics.html">Logistics</a></li>
                        </ul>


                    </li>

                    <li className="mobile-nav__link">

                        <span className="mobile-nav__subnav-toggle js-mobile-nav-subnav-toggle">Products</span>

                        <ul className="mobile-nav__subnav js-mobile-nav-subnav">
                            <li className="mobile-nav__subnav-item "><a href="our-produce.html">Our Produce</a></li>
                            <li className="mobile-nav__subnav-item "><a href="our-produce/food-safety.html">Food Safety</a></li>
                            <li className="mobile-nav__subnav-item mobile-nav__subnav-item--external"><a
                                href="https://www.sweetmamaproduce.com/" target="_blank" rel="noopener">Sweet Mama</a></li>
                        </ul>


                    </li>

                    <li className="mobile-nav__link">

                        <a href="contact.html" className="no-barba">Contact</a>


                    </li>

                    <ul className="mobile-nav__social">
                        <li className="mobile-nav__social-item" target="_blank">
                            <a href="https://www.linkedin.com/company/ruby-robinson-co.-inc."><svg xmlns="http://www.w3.org/2000/svg"
                                width="18px" height="18px" viewBox="0 0 18 18">
                                <path
                                    d="M.218 17.383h3.71V6.231H.218zM2.072 4.707H2.05C.804 4.707 0 3.849 0 2.779 0 1.685.83.851 2.096.851c1.27 0 2.051.834 2.074 1.928 0 1.07-.805 1.928-2.098 1.928M17.297 17.384H13.59v-5.968c0-1.498-.536-2.523-1.879-2.523-1.022 0-1.634.693-1.902 1.358-.099.239-.123.569-.123.904v6.229H5.978s.048-10.108 0-11.153h3.707v1.58c.494-.763 1.374-1.842 3.343-1.842 2.439 0 4.27 1.592 4.27 5.02v6.395z">
                                </path>
                            </svg>
                            </a>
                        </li>
                        <li className="mobile-nav__social-item" target="_blank">
                            <a href="https://www.facebook.com/TheRubyCo/"><svg xmlns="http://www.w3.org/2000/svg" width="9px"
                                height="18px" viewBox="0 0 9 18">
                                <path
                                    d="M8.4 8.655H5.976v8.642H2.385V8.655H.675V5.602h1.71V3.626C2.385 2.21 3.056 0 6.009 0l2.66.01v2.965H6.74c-.315 0-.763.157-.763.833v1.794h2.739L8.4 8.655z">
                                </path>
                            </svg>
                            </a>
                        </li>
                        <li className="mobile-nav__social-item" target="_blank">
                            <a href="https://www.instagram.com/_therubycompany/"><svg xmlns="http://www.w3.org/2000/svg" width="16px"
                                height="16px" viewBox="0 0 16 16">
                                <path d="M7.75 5.165a2.583 2.583 0 100 5.166 2.583 2.583 0 000-5.166"></path>
                                <path
                                    d="M11.885 4.542a.93.93 0 11-.001-1.859.93.93 0 010 1.86M7.75 11.727a3.98 3.98 0 110-7.958 3.98 3.98 0 010 7.958m7.7-7.174c-.036-.825-.167-1.389-.358-1.88a3.787 3.787 0 00-.894-1.374 3.796 3.796 0 00-1.373-.894c-.493-.19-1.055-.322-1.88-.36C10.117.01 9.853 0 7.749 0s-2.37.01-3.195.046c-.824.038-1.388.17-1.881.36-.51.198-.941.463-1.372.894-.431.43-.696.863-.895 1.373-.191.492-.322 1.056-.36 1.881C.01 5.38 0 5.644 0 7.748c0 2.105.009 2.368.047 3.195.037.825.168 1.388.359 1.881a3.8 3.8 0 00.895 1.372 3.78 3.78 0 001.372.894c.493.191 1.057.323 1.88.36.827.038 1.092.047 3.196.047s2.368-.009 3.195-.047c.825-.037 1.387-.169 1.88-.36.51-.197.942-.463 1.373-.894.43-.43.696-.862.894-1.372.19-.493.322-1.056.359-1.88.039-.828.047-1.09.047-3.196 0-2.104-.008-2.368-.047-3.194">
                                </path>
                            </svg>
                            </a>
                        </li>
                    </ul>
                </ul>
            </nav> */}
        </div >
    );
}


export default withRouter(Header)
