import React from 'react';
import { Link } from 'react-router-dom';


import Bannar from '../../components/Bannar';

import aboutImg from '../../assets/img/home-about.jpg'


export default function Home() {
    return (
        <>

            <div className="l-site-content js-site-content">
                <div className="js-nav-is-flickable waypoint waypoint--flick-nav"></div>
                <div className="js-nav-has-scrolled waypoint waypoint--has-scrolled"></div>

                <Bannar />

                <div className="l-50-50 l-50-50--reset-order l-50-50--img-last-mobile">

                    <div className="l-50-50__col">
                        <div className="l-50-50__row">
                            <div className="blurb ">
                                <h6 className="blurb__eyebrow">
                                    Our Company
                                </h6>
                                <h2 className="blurb__heading">
                                    A little about us
                                </h2>
                                <div className="blurb__body">
                                    <p>Zora is more than a modern tech company. Zora is boundaryless. We do more than just use AI, we harnesses the true power of technology to work for you.</p>
                                    <p style={{marginTop: '10px'}}>Zora solves personal, professional, and organizational challenges by leveraging expertise with innovation. We dream, create and realize ambitions.</p>
                                </div>
                                <div className="blurb__btn">
                                    <Link className='btn' to="/about-us">Learn More</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="l-50-50__col">
                        <div className="l-50-50__fill">
                            <img className="fill-img lazyload" src={aboutImg} />
                        </div>
                    </div>

                </div>


                {/*<div className="cta-large  js-parallax-container">*/}
                {/*    <div className="cta-large__inner">*/}

                {/*        <div className="cta-large__pattern js-parallax-object" data-scroll="300">*/}
                {/*            <div className="cta-large__pattern-img">*/}
                {/*                <img className="lazyload" src={aboutImg} />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="cta-large__text">*/}

                {/*            <div className="blurb blurb--border blurb--xl">*/}
                {/*                <h2 className="blurb__heading">*/}
                {/*                    Our Products*/}
                {/*                </h2>*/}
                {/*                <div className="blurb__body">*/}
                {/*                    <p>We can source anything and everything you need, from anywhere in the country. And ensure it gets*/}
                {/*                        where it needs to go&nbsp;<span className="caps">ASAP</span>.&nbsp;</p>*/}

                {/*                </div>*/}
                {/*                <div className="blurb__btn">*/}
                {/*                    <Link className='btn btn--on-lemon' to="/products">View Our Products </Link>*/}

                {/*                </div>*/}
                {/*            </div>*/}

                {/*        </div>*/}

                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="l-50-50 l-50-50--reset-order">*/}

                {/*    <div className="l-50-50__col">*/}
                {/*        <div className="l-50-50__row">*/}
                {/*            <div className="blurb ">*/}
                {/*                <h6 className="blurb__eyebrow">*/}
                {/*                    Our Partners*/}
                {/*                </h6>*/}
                {/*                <h2 className="blurb__heading">*/}
                {/*                    Our nationwide network is the best.&nbsp;Period.*/}
                {/*                </h2>*/}
                {/*                <div className="blurb__body">*/}
                {/*                    <p>And packed to the gills with growers and shippers we’re proud to work&nbsp;with.&nbsp;</p>*/}

                {/*                </div>*/}
                {/*                <div className="blurb__btn">*/}
                {/*                    <Link className='btn' to="/about-us">Meet our Team</Link>*/}

                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <div className="l-50-50__col">*/}
                {/*        <div className="l-50-50__fill">*/}
                {/*            <img className="fill-img lazyload"*/}
                {/*                src="data:image/jpeg;base64,%2F9j%2F4AAQSkZJRgABAQAAAQABAAD%2F2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P%2F2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P%2FwAARCAATABADAREAAhEBAxEB%2F8QAFgABAQEAAAAAAAAAAAAAAAAABgQF%2F8QAIhAAAgICAQMFAAAAAAAAAAAAAQIDBAARIQUGQRIiMVFi%2F8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECA%2F%2FEABgRAQEBAQEAAAAAAAAAAAAAAAABEQIx%2F9oADAMBAAIRAxEAPwCLujpdKjYWGijvKxLMq%2B4KPrWThis5KyMACF3xsc45hF1CwksNq5IGNiQMo%2FA8ZNmnPRe2D6jseed4SK6sq63YmSJI1lYKRyAfnLxmzJSd4B%2F%2F2Q%3D%3D"*/}
                {/*                data-srcset="https://ruby-co.imgix.net/half-width-images/ruby-home-partners-fpo.jpg?crop=focalpoint&amp;domain=ruby-co.imgix.net&amp;fit=crop&amp;fm=pjpg&amp;fp-x=0.5&amp;fp-y=0.5&amp;h=997&amp;ixlib=php-2.2.0&amp;q=82&amp;w=800 800w, https://ruby-co.imgix.net/half-width-images/ruby-home-partners-fpo.jpg?crop=focalpoint&amp;domain=ruby-co.imgix.net&amp;fit=crop&amp;fm=pjpg&amp;fp-x=0.5&amp;fp-y=0.5&amp;h=1995&amp;ixlib=php-2.2.0&amp;q=61&amp;w=1600 1600w, https://ruby-co.imgix.net/half-width-images/ruby-home-partners-fpo.jpg?crop=focalpoint&amp;domain=ruby-co.imgix.net&amp;fit=crop&amp;fm=pjpg&amp;fp-x=0.5&amp;fp-y=0.5&amp;h=718&amp;ixlib=php-2.2.0&amp;q=60&amp;w=576 576w, https://ruby-co.imgix.net/half-width-images/ruby-home-partners-fpo.jpg?crop=focalpoint&amp;domain=ruby-co.imgix.net&amp;fit=crop&amp;fm=pjpg&amp;fp-x=0.5&amp;fp-y=0.5&amp;h=1436&amp;ixlib=php-2.2.0&amp;q=45&amp;w=1152 1152w"*/}
                {/*                data-sizes="auto" />*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*</div>*/}

            </div>
        </>
    )
}
