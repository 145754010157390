import React from 'react';
import BreadCrumbs from '../../components/BreadCrumbs';
import breadcrumb from '../../assets/img/breadcrumb.jpg';
import treewayImg from '../../assets/img/treeway.bg.png';
import silverbImg from '../../assets/img/silverbird2.jpg';
import artemis from '../../assets/img/artemis.jpg'
import { team } from '../../data';
import { Team } from '../../components/Team';




export default function About() {
    return <div className="l-site-content js-site-content">
        <BreadCrumbs
            url={breadcrumb}
            title="Products"
        />

        <div className="l-50-50" id="1">

            <div className="l-50-50__col">

                <div className="l-50-50__row l-50-50__row--bg-garlic">
                    <div className="anim-inf js-anim-in">


                        <div className="blurb blurb--on-garlic">
                            <h6 className="blurb__eyebrow">
                                Treeway app
                            </h6>
                            <h2 className="blurb__heading">
                                Connect with your audience, wherever you are
                            </h2>
                            <div className="blurb__body">
                                <p>Whether you are a pastor connecting with your parish, a small business communicating with your clients, or a community group looking to message the masses,
                                    <strong>Treeway is the new application that makes this both possible, and easy.</strong>.
                                </p>

                            </div>
                            <a href="https://treeway.io" target="_blank" rel="noopener noreferrer" >See More</a>
                        </div>
                    </div>
                </div>

            </div>

            <div className="l-50-50__col">
                <div className="l-50-50__fill">
                    <img className="fill-img lazyload" src={treewayImg} />
                </div>
            </div>

        </div>

        <div className="l-50-50" id="2">

            <div className="l-50-50__col">

                <div className="l-50-50__row l-50-50__row--bg-white">
                    <div className="anim-ina js-anim-in">


                        <div className="blurb blurb--on-white">
                            <h6 className="blurb__eyebrow">
                                Silverbird
                            </h6>
                            <h2 className="blurb__heading">
                                AI-driven beauty app
                            </h2>
                            <div className="blurb__body">
                                <p>With the Silverbird Customer App, our salon clients gain efficiencies and control of their appointments, communications and payments. Essentially, we put them in the driver seat and, in turn, our teams spend more time interacting with clients who are in the salon while knowing the ones who aren’t are perfectly satisfied. Clients are able to book/re-book past appointments and buy products they’ve purchased in the past in a few simple clicks. </p>

                            </div>
                            <a href="https://silverbird.io" target="_blank" rel="noopener noreferrer" >See More</a>
                        </div>
                    </div>
                </div>

            </div>

            <div className="l-50-50__col">
                <div className="l-50-50__fill">
                    <img className="fill-img lazyload" src={silverbImg} />
                </div>
            </div>

        </div>
        <div className="l-50-50" id="2">

            <div className="l-50-50__col">

                <div className="l-50-50__row l-50-50__row--bg-garlic">
                    <div className="anim-inf js-anim-in">


                        <div className="blurb blurb--on-garlic">
                            <h6 className="blurb__eyebrow">
                                Artemis
                            </h6>
                            <h2 className="blurb__heading">
                                Our job is to help you land a job you love.|
                            </h2>
                            <div className="blurb__body">
                                <p>Artemis provides an unparalleled level of personalized recruiting services.  Our expertise and commitment to quality make us a resource to candidates throughout their career development and growth.</p>

                            </div>
                            {/*<a href="#" target="_blank" rel="noopener noreferrer" >See More</a>*/}
                        </div>
                    </div>
                </div>

            </div>

            <div className="l-50-50__col">
                <div className="l-50-50__fill">
                    <img className="fill-img lazyload" src={artemis} />
                </div>
            </div>

        </div>



    </div>
}
