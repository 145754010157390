import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';



export default function Layout(props) {
    return (
        <div>
            <Header />

            <div id="barba-wrapper">
                <div className="barba-container">

                    {props.children}
                    <Footer />

                </div>
            </div>



        </div>
    );
}
