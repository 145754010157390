import React from 'react';
import './bread-crumbs.css'

export default function BreadCrumbs({ title, url }) {
    return (
        <div className='bread-crumbs-wrrapper' style={{ backgroundImage: `url(${url})` }}   >
            <h1 className='bread-crumbs-title'>{title}</h1>
            <p className="m-b-md" style={{ color: 'white' }}>Home &#62; {title}</p>
        </div>
    );
}
