import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../components/BreadCrumbs';
import breadcrumb from '../../assets/img/breadcrumb.jpg'
import { team, executive } from '../../data';
import { Team } from '../../components/Team';
import aboutImg from '../../assets/img/home-about.jpg';
//import kunleBadmus from '../../assets/img/kunleBadmus.jpg'



export default function About() {
    return <div className="l-site-content js-site-content">
        <BreadCrumbs
            url={breadcrumb}
            title="About us"
        />

        <div className="l-50-50 l-50-50--reset-order l-50-50--img-last-mobile">

            <div className="l-50-50__col">
                <div className="l-50-50__row">
                    <div className="blurb ">
                        <h6 className="blurb__eyebrow">
                            Our Company
                        </h6>
                        <h2 className="blurb__heading">
                            About us
                        </h2>
                        <div className="blurb__body">
                            <p>Zora is a collective. We are a like-minded group of entrepreneurs, disruptors, thought leaders, and creatives with the sole aim of providing robust and credible AI solutions for your business and your life. Our mission is to bring AI to the masses through innovative yet user-friendly solutions that answer problems. Our vision is to create a strong entity that is the undisputed authority on all things AI; to become world leaders in the field and to share the power of AI with humanity. </p>


                            <h3 style={{fontWeight: 700,marginTop: '10px'}}>Zora has five core values: </h3>
                            <p>- Innovation<br/>
                                – Quality<br/>
                                – Simplicity<br/>
                                – Collaboration<br/>
                                – Honesty </p>

                            <p>These core values underpin everything we do at Zora and infiltrates everything we create.</p>


                        </div>

                    </div>
                </div>
            </div>

            <div className="l-50-50__col">
                <div className="l-50-50__fill">
                    <img className="fill-img lazyload" src={aboutImg} />
                </div>
            </div>

        </div>

        <div className="l-team section-padding" style={{display: 'none'}}>

            <div className="l-team__header">
                <div className="js-anim-in ">


                    <div className="blurb blurb--l blurb--centered">
                        <h6 className="blurb__eyebrow">
                            Our Team
                        </h6>
                        <h2 className="blurb__heading">
                            Meet the folks who steer the&nbsp;ship.
                        </h2>
                    </div>
                </div>
            </div>

            <h1 className="sub-title">Leadership</h1>
            <div className="team-management-wrapper">
                {
                    executive.map(e => {
                        return <Team
                            name={e.name}
                            src={e.src}
                            position={e.position}
                            phone={e.phone}
                            email={e.email}
                            bio={e.bio}

                        />
                    })
                }
            </div>

            <h1 className="sub-title">The Team</h1>
            <div className="team-management-wrapper">
                {
                    team.map(e => {
                        return <Team
                            name={e.name}
                            src={e.src}
                            position={e.position}
                            phone={e.phone}
                            email={e.email}
                            bio={e.bio}

                        />
                    })
                }
            </div>



        </div>
    </div>
}
