import React from 'react';
// import agencyWeb from '../../assets/img/ford-agency-map.png';
// import agencyMob from '../../assets/img/ford-agency-map.png';


export default function Footer() {
    return (
        <div className="l-site-footer">

            {/* <div className="footer">
                <div className="Footer-row">
                    <h4 className="Footer-header">Zoracorp</h4>
                    <p className="Footer-txt break">1660 L Street NW, Suite 200<br />
                        Washington, DC 20036</p>
                    <p className="Footer-txt">P.202.223.8244</p>
                    <p className="Footer-txt">F.202.466.6167</p>
                </div>
                <div className="Footer-row">
                    <h4 className="Footer-header">Direct Hire<br />Services</h4>
                    <p className="Footer-txt break">P.202.223.8244</p>
                    <h4 className="Footer-header">For Clients</h4>
                    <p className="Footer-txt title">Kunle Badmus</p>
                    <p className="Footer-txt">President, Founder</p>
                    <p className="Footer-txt m-b-2"><a href="mailto:kgbadamosi@gmail.com">kgbadamosi@gmail.com</a></p>
                    <p className="Footer-txt title"></p>
                    <p className="Footer-txt"></p>
                    <p className="Footer-txt"><a href="mailto:"></a></p>
                </div>
                <div className="Footer-row">
                    <h4 className="Footer-header">Temporary and<br />Temp-To-Hire Services</h4>
                    <p className="Footer-txt break">P.202.223.8244</p>
                    <h4 className="Footer-header">For Clients</h4>
                    <p className="Footer-txt title">Seyi Badmus</p>
                    <p className="Footer-txt">Vice President, Principal</p>
                    <p className="Footer-txt m-b-2"><a href="mailto:kgbadamosi@gmail.com">kgbadamosi@gmail.com</a></p>
                    <p className="Footer-txt title">Seyi Badmus</p>
                    <p className="Footer-txt">Associate Partner</p>
                    <p className="Footer-txt"><a href="mailto:kgbadamosi@gmail.com">kgbadamosi@gmail.com</a></p>
                </div>
                <div className="Footer-row social-links">
                    <a className="Footer-icon" href="#" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
                            <linearGradient id="Ld6sqrtcxMyckEl6xeDdMa" x1="9.993" x2="40.615" y1="9.993" y2="40.615" gradientUnits="userSpaceOnUse">
                                <stop offset="0" stopColor="#2aa4f4" />
                                <stop offset="1" stopColor="#007ad9" />
                            </linearGradient>
                            <path fill="url(#Ld6sqrtcxMyckEl6xeDdMa)" d="M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z" />
                            <path fill="#fff" d="M26.707,29.301h5.176l0.813-5.258h-5.989v-2.874c0-2.184,0.714-4.121,2.757-4.121h3.283V12.46 c-0.577-0.078-1.797-0.248-4.102-0.248c-4.814,0-7.636,2.542-7.636,8.334v3.498H16.06v5.258h4.948v14.452 C21.988,43.9,22.981,44,24,44c0.921,0,1.82-0.084,2.707-0.204V29.301z" />
                        </svg>
                    </a>
                    <a className="Footer-icon" href="#" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px"><path fill="#03A9F4" d="M42,12.429c-1.323,0.586-2.746,0.977-4.247,1.162c1.526-0.906,2.7-2.351,3.251-4.058c-1.428,0.837-3.01,1.452-4.693,1.776C34.967,9.884,33.05,9,30.926,9c-4.08,0-7.387,3.278-7.387,7.32c0,0.572,0.067,1.129,0.193,1.67c-6.138-0.308-11.582-3.226-15.224-7.654c-0.64,1.082-1,2.349-1,3.686c0,2.541,1.301,4.778,3.285,6.096c-1.211-0.037-2.351-0.374-3.349-0.914c0,0.022,0,0.055,0,0.086c0,3.551,2.547,6.508,5.923,7.181c-0.617,0.169-1.269,0.263-1.941,0.263c-0.477,0-0.942-0.054-1.392-0.135c0.94,2.902,3.667,5.023,6.898,5.086c-2.528,1.96-5.712,3.134-9.174,3.134c-0.598,0-1.183-0.034-1.761-0.104C9.268,36.786,13.152,38,17.321,38c13.585,0,21.017-11.156,21.017-20.834c0-0.317-0.01-0.633-0.025-0.945C39.763,15.197,41.013,13.905,42,12.429" /></svg>
                    </a>
                    <a className="Footer-icon" href="#" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px"><path fill="#0288D1" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z" /><path fill="#FFF" d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z" /></svg>
                    </a>
                    <a className="Footer-icon" href="#" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
                            <radialGradient id="yOrnnhliCrdS2gy~4tD8ma" cx="19.38" cy="42.035" r="44.899" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#fd5" /><stop offset=".328" stopColor="#ff543f" /><stop offset=".348" stopColor="#fc5245" /><stop offset=".504" stopColor="#e64771" /><stop offset=".643" stopColor="#d53e91" /><stop offset=".761" stopColor="#cc39a4" /><stop offset=".841" stopColor="#c837ab" /></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8ma)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z" /><radialGradient id="yOrnnhliCrdS2gy~4tD8mb" cx="11.786" cy="5.54" r="29.813" gradientTransform="matrix(1 0 0 .6663 0 1.849)" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#4168c9" />
                                <stop offset=".999" stopColor="#4168c9" stopOpacity="0" />
                            </radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8mb)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z" /><path fill="#fff" d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z" /><circle cx="31.5" cy="16.5" r="1.5" fill="#fff" /><path fill="#fff" d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z" /></svg>
                    </a>
                </div>

            </div> */}
            <div className="l-site-footer__copyright">
                <p className="body-robinson-s">&copy; {new Date().getFullYear()} zoracorp.com all rights reserve. </p>
            </div>

        </div>
    );
}
